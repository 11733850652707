export default {
  cs: {
    app: {
      appName: 'Middleware',
    },
    layout: {
      components: {
        aside: {
          menu: {
            articleList: 'Seznamy artiklů',
            dashboard: 'Dashboard',
            shop: 'Prodejny',
            pointOfSale: 'Pokladny',
            pointOfSaleAttribute: 'POS attributy',
            cashier: 'Pokladní',
            cashierRole: 'Role',
            configuration: 'Konfigurace',
            pointOfSaleConfiguration: 'Konfigurace POS',
            middlewareConfiguration: 'Middleware',
            promotionList: 'Promoční koše',
            promotion: 'Promoce',
            import: 'Dávkové importy',
            voucher: 'Poukázky',
            voucherEmission: 'Emise poukázek',
            voucherOverview: 'Přehled poukázek',
            voucherSearch: 'Poukázky helpdesk',
            transaction: 'Transakce',
            report: 'Reporty',
            reportDefinition: 'Definice reportů',
            chat: 'Chatbot',
          },
        },
      },
    },
    general: {
      document: {
        types: {
          sellDocument: 'Prodej',
          closeDay: 'Uzávěrka',
          financialCloseDay: 'Finanční uzávěrka',
          printDocument: 'Dokument',
          nonFiscalDocument: 'Nefiskální dokument',
          finDocument: 'Finanční dokument',
          safebag: 'Safebag',
          income: 'Příjmový doklad',
          expenditure: 'Výdejový doklad',
          stockReport: 'Stav skladu',
          inventoryDocument: 'Inventura',
          posStateDocument: 'Stav pokladny',
          posPayment: 'Platby',
          lotteryCloseDay: 'Uzávěrka loterie',
          openCashDrawer: 'Manuální otevření zásuvky',
          posPaymentZReport: 'Uzávěrka plateb. terminálu',
          posPaymentCancel: 'Zrušení platby',
          posPaymentRefund: 'Vrácení platby',
          dialogResult: 'Potvrzení obsluhy',
          inventorySummaryDocument: 'Inventura (souhrn)',
          inventoryFindDiffsDocument: 'Inventura (hledání rozdílů)',
          initPrinter: 'Inicializace tiskárny',
          contentDelayedSellStorno: 'Návrat - paragon',
          contentDelayedSell: 'Prodej - paragon',
          logisticDocument: 'Logistický dokument',
          goodsReceiptFromDC: 'Příjem zboží z DC',
          goodsReturnToDC: 'Návrat zboží do DC',
          goodsOrderFromDC: 'Objednávka zboží z DC',
          goodsReceiptFromPress: 'Příjem tiskovin',
          goodsReceiptFromExternalSupplier: 'Příjem zboží od externího dodavatele',
          goodsReturnToExternalSupplier: 'Návrat zboží externímu dodavateli',
          goodsOrderFromExternalSupplier: 'Objednávka zboží od externího dodavatele',
          goodsReturnToPress: 'Remitenda tiskovin',
          goodsDepreciationConsumption: 'Odpis do spotřeby',
          goodsDepreciationDisposal: 'Odpis do likvidace',
          stockBalancing: 'Rovnání skladu',
          goodsReceiptFromPlant: 'Přeskladnění příjem',
          goodsTransferToPlant: 'Přeskladnění výdej',
          customerDetail: 'Detail zákazníka',
          display: 'Externí displej',
          posSalesDocument: 'Report prodeje',
          safeBag: 'Safebag',
        },
      },
    },
  },
  sk: {
    app: {
      appName: 'Middleware',
    },
    layout: {
      components: {
        aside: {
          menu: {
            articleList: 'Seznamy artiklů',
            dashboard: 'Dashboard',
            shop: 'Predajne',
            pointOfSale: 'Pokladne',
            pointOfSaleAttribute: 'Atribúty pokladne',
            cashier: 'Pokladník',
            configuration: 'Konfigurácia',
            pointOfSaleConfiguration: 'Konfigurácia pokladne',
            middlewareConfiguration: 'Middleware',
            promotionList: 'Zoznam akcií',
            promotion: 'Akcia',
            import: 'Dávkové importy',
            voucher: 'Kupóny',
            voucherEmission: 'Emisia kuponov',
            voucherOverview: 'Prehľad kuponov',
            voucherSearch: 'Vyhľadávanie kuponov',
            transaction: 'Transakcie',
            report: 'Reporty',
            reportDefinition: 'Definícia reportov',
            chat: 'Chatbot',
          },
        },
      },
    },
    general: {
      document: {
        types: {
          sellDocument: 'Predaj',
          closeDay: 'Uzávierka',
          financialCloseDay: 'Finančná uzávierka',
          printDocument: 'Dokument',
          nonFiscalDocument: 'Nefiskálny dokument',
          finDocument: 'Finančný dokument',
          safebag: 'Safebag',
          income: 'Príjmový doklad',
          expenditure: 'Výdavkový doklad',
          stockReport: 'Stav skladu',
          inventoryDocument: 'Inventúra',
          posStateDocument: 'Stav pokladne',
          posPayment: 'Platby',
          lotteryCloseDay: 'Uzávierka lotérie',
          openCashDrawer: 'Manuálne otvorenie zásuvky',
          posPaymentZReport: 'Uzávierka platobného terminálu',
          posPaymentCancel: 'Zrušenie platby',
          posPaymentRefund: 'Vrátenie platby',
          dialogResult: 'Potvrdenie obsluhy',
          inventorySummaryDocument: 'Inventúra (súhrn)',
          inventoryFindDiffsDocument: 'Inventúra (hľadanie rozdielov)',
          initPrinter: 'Inicializácia tlačiarne',
          contentDelayedSellStorno: 'Návrat - paragon',
          contentDelayedSell: 'Predaj - paragon',
          logisticDocument: 'Logistický dokument',
          goodsReceiptFromDC: 'Prijatie tovaru z DC',
          goodsReturnToDC: 'Návrat tovaru do DC',
          goodsOrderFromDC: 'Objednávka tovaru z DC',
          goodsReceiptFromPress: 'Prijatie tlačív',
          goodsReceiptFromExternalSupplier: 'Prijatie tovaru od externého dodávateľa',
          goodsReturnToExternalSupplier: 'Návrat tovaru externému dodávateľovi',
          goodsOrderFromExternalSupplier: 'Objednávka tovaru od externého dodávateľa',
          goodsReturnToPress: 'Remitenda tlačív',
          goodsDepreciationConsumption: 'Odpis do spotreby',
          goodsDepreciationDisposal: 'Odpis do likvidácie',
          stockBalancing: 'Vyrovnanie skladu',
          goodsReceiptFromPlant: 'Prestavenie skladu príjem',
          goodsTransferToPlant: 'Prestavenie skladu výdaj',
          customerDetail: 'Detail zákazníka',
          display: 'Externý displej',
          posSalesDocument: 'Report predaja',
          safeBag: 'Safebag',
        },
      },
    },
  },
  en: {
    app: {
      appName: 'Middleware',
    },
    layout: {
      components: {
        aside: {
          menu: {
            articleList: 'Article lists',
            dashboard: 'Dashboard',
            shop: 'Shops',
            pointOfSale: 'Points of sale',
            pointOfSaleAttribute: 'POS attributes',
            cashier: 'Cashiers',
            configuration: 'Configuration',
            pointOfSaleConfiguration: 'POS configuration',
            middlewareConfiguration: 'Middleware',
            promotionList: 'Promotion lists',
            promotion: 'Promotions',
            import: 'Batch imports',
            voucher: 'Vouchers',
            voucherEmission: 'Voucher emission',
            voucherOverview: 'Voucher overview',
            voucherSearch: 'Voucher helpdesk',
            transaction: 'Transactions',
            report: 'Reports',
            reportDefinition: 'Report definitions',
            chat: 'Chatbot',
          },
        },
      },
    },
    general: {
      document: {
        types: {
          sellDocument: 'Sell document',
          closeDay: 'Close day',
          financialCloseDay: 'Financial close day',
          printDocument: 'Print document',
          nonFiscalDocument: 'Non fiscal document',
          finDocument: 'Fin document',
          safebag: 'Safebag',
          income: 'Income',
          expenditure: 'Expenditure',
          stockReport: 'Stock report',
          inventoryDocument: 'Inventory document',
          posStateDocument: 'POS state document',
          posPayment: 'POS payment',
          lotteryCloseDay: 'Lottery close day',
          openCashDrawer: 'Open cash drawer',
          posPaymentZReport: 'POS payment Z report',
          posPaymentCancel: 'POS payment cancel',
          posPaymentRefund: 'POS payment refund',
          dialogResult: 'Dialog result',
          inventorySummaryDocument: 'Inventory summary document',
          inventoryFindDiffsDocument: 'Inventory find diffs document',
          initPrinter: 'Init printer',
          contentDelayedSellStorno: 'Content delayed sell storno',
          contentDelayedSell: 'Content delayed sell',
          logisticDocument: 'Logistic document',
          goodsReceiptFromDC: 'Goods receipt from DC',
          goodsReturnToDC: 'Goods return to DC',
          goodsOrderFromDC: 'Goods order from DC',
          goodsReceiptFromPress: 'Goods receipt from press',
          goodsReceiptFromExternalSupplier: 'Goods receipt from external supplier',
          goodsReturnToExternalSupplier: 'Goods return to external supplier',
          goodsOrderFromExternalSupplier: 'Goods order from external supplier',
          goodsReturnToPress: 'Goods return to press',
          goodsDepreciationConsumption: 'Goods depreciation consumption',
          goodsDepreciationDisposal: 'Goods depreciation disposal',
          stockBalancing: 'Stock balancing',
          goodsReceiptFromPlant: 'Goods receipt from plant',
          goodsTransferToPlant: 'Goods transfer to plant',
          customerDetail: 'Customer detail',
          display: 'Display',
          posSalesDocument: 'POS sales document',
          safeBag: 'Safebag',
        },
      },
    },
  },
  de: {
    app: {
      appName: 'Middleware',
    },
    layout: {
      components: {
        aside: {
          menu: {
            articleList: 'Artikellisten',
            dashboard: 'Dashboard',
            shop: 'Geschäfte',
            pointOfSale: 'Kasse',
            pointOfSaleAttribute: 'POS Attribute',
            cashier: 'Kassierer',
            configuration: 'Konfiguration',
            pointOfSaleConfiguration: 'Kassenkonfiguration',
            middlewareConfiguration: 'Middleware',
            promotionList: 'Aktionenliste',
            promotion: 'Aktion',
            import: 'Stapelimport',
            voucher: 'Gutscheine',
            voucherEmission: 'Gutschein Ausstellung',
            voucherOverview: 'Gutscheinübersicht',
            voucherSearch: 'Gutschein-Suche',
            transactions: 'Transaktionen',
            report: 'Berichte',
            reportDefinition: 'Berichtsdefinitionen',
            chat: 'Chatbot',
          },
        },
      },
    },
    general: {
      document: {
        types: {
          sellDocument: 'Verkauf',
          closeDay: 'Tagesabschluss',
          financialCloseDay: 'Finanzieller Tagesabschluss',
          printDocument: 'Dokument drucken',
          nonFiscalDocument: 'Nicht-fiskalisches Dokument',
          finDocument: 'Finanzdokument',
          safebag: 'Safebag',
          income: 'Einnahmebeleg',
          expenditure: 'Ausgabebeleg',
          stockReport: 'Lagerbericht',
          inventoryDocument: 'Inventur',
          posStateDocument: 'Kassenstatus-Dokument',
          posPayment: 'Zahlungen',
          lotteryCloseDay: 'Lotterie Tagesabschluss',
          openCashDrawer: 'Manuelles Öffnen der Kassenschublade',
          posPaymentZReport: 'Z-Bericht für POS-Zahlungen',
          posPaymentCancel: 'Zahlungsstornierung',
          posPaymentRefund: 'Rückerstattung der Zahlung',
          dialogResult: 'Bestätigung des Dialogs',
          inventorySummaryDocument: 'Inventurzusammenfassung',
          inventoryFindDiffsDocument: 'Inventursuche nach Differenzen',
          initPrinter: 'Drucker initialisieren',
          contentDelayedSellStorno: 'Rückgabe - Beleg',
          contentDelayedSell: 'Verkauf - Beleg',
          logisticDocument: 'Logistikdokument',
          goodsReceiptFromDC: 'Wareneingang aus dem DC',
          goodsReturnToDC: 'Warenrücksendung an das DC',
          goodsOrderFromDC: 'Warenbestellung aus dem DC',
          goodsReceiptFromPress: 'Wareneingang von der Presse',
          goodsReceiptFromExternalSupplier: 'Wareneingang vom externen Lieferanten',
          goodsReturnToExternalSupplier: 'Warenrücksendung an externen Lieferanten',
          goodsOrderFromExternalSupplier: 'Warenbestellung vom externen Lieferanten',
          goodsReturnToPress: 'Rücksendung von Druckerzeugnissen',
          goodsDepreciationConsumption: 'Abschreibung des Verbrauchs',
          goodsDepreciationDisposal: 'Abschreibung der Entsorgung',
          stockBalancing: 'Lagerausgleich',
          goodsReceiptFromPlant: 'Wareneingang von der Anlage',
          goodsTransferToPlant: 'Warenübertragung zur Anlage',
          customerDetail: 'Kundendetail',
          display: 'Anzeige',
          posSalesDocument: 'POS-Verkaufsdokument',
          safeBag: 'Safebag',
        },
      },
    },
  },
};
