import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.transactionList,
    },
  },
  // {
  //   path: '/transaction/:id',
  //   name: 'transaction',
  //   component: () => import('./Detail/DetailPage.vue'),
  //   props: true,
  //   redirect: {name: 'transaction-items'},
  //   children: [
  //     {
  //       path: 'items',
  //       name: 'transaction-items',
  //       component: () => import('./Detail/tabs/TabItems.vue'),
  //     },
  //   ],
  // },
] as RegisterRoutesOptions;
